import { RawLogicsConnection, RawLogicsInput, RawLogicsOperator, RawLogicsOutput } from "../rawLogics/RawOperator";
import { LogicsData, LogicsVariable, fromRawVariables } from "./LogicsVariable";

export interface LogicsOperator {
    name: string;
    description: string;

    inputs: LogicsIo[];
    outputs: LogicsIo[];

    data?: LogicsData[];
    variables: LogicsVariable[];

    tags: string[];
}

export interface LogicsIo {
    label: string;
    description: string;

    type: string;
    required: boolean;
    enabledByDefault: boolean;

    connection: LogicsConnection;
}

type LogicsConnection = LogicsConnectionModeSingle | LogicsConnectionModeMultiple | LogicsConnectionModeSpread;

interface LogicsConnectionModeSingle {
    mode: "single";
}

interface LogicsConnectionModeMultiple {
    mode: "multiple";
    counter: string;
}

interface LogicsConnectionModeSpread {
    mode: "spread";
    counter: string;
}

export function fromRawOperators(rawOperators: RawLogicsOperator[]): LogicsOperator[] {
    return rawOperators.map(fromRawOperator);
}

function fromRawOperator(rawOperator: RawLogicsOperator): LogicsOperator {
    return {
        name: rawOperator.name,
        description: rawOperator.description,

        // TODO: maybe add data to operators
        data: undefined, //rawOperator.data?.map(fromRawData),
        variables: fromRawVariables(rawOperator.variables),

        tags: rawOperator.tags ?? [],

        inputs: rawOperator.inputs.map(fromRawInput),
        outputs: rawOperator.outputs.map(fromRawOutput),
    };
}

function fromRawInput(rawIo: RawLogicsInput): LogicsIo {
    return {
        label: rawIo.name,
        description: rawIo.description ?? "[ No description ]",

        type: rawIo.type,
        required: (rawIo.required ?? false) || rawIo.connection_mode === "spread",
        enabledByDefault: true,

        connection: connectionFromRawIo(rawIo),
    };
}

function fromRawOutput(rawIo: RawLogicsOutput): LogicsIo {
    return {
        label: rawIo.name,
        description: rawIo.description ?? "[ No description ]",

        type: rawIo.type,
        required: (rawIo.required ?? false) || rawIo.connection_mode === "spread",
        enabledByDefault: rawIo.enabled_by_default ?? true,

        connection: connectionFromRawIo(rawIo),
    };
}

function connectionFromRawIo(connection: RawLogicsConnection): LogicsConnection {
    switch (connection.connection_mode) {
        case "single":
            return {
                mode: "single",
            };
        case "multiple":
            if (connection.connection_counter === undefined) {
                throw new Error(`Multiple connection has no counter`);
            }

            return {
                mode: "multiple",
                counter: connection.connection_counter,
            };
        case "spread":
            if (connection.connection_counter === undefined) {
                throw new Error(`Spread connection has no counter`);
            }

            return {
                mode: "spread",
                counter: connection.connection_counter,
            };
        default:
            let _: never = connection;
            throw new Error(`Corrupted connection mode`);
    }
}
